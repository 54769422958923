// eslint-disable-next-line
import svg from 'bundle-text:../icons/copy.svg';
import EpfbFlashMessageWebComponent from './EpfbFlashMessageWebComponent';
import escapeHtml from './escapeHtml';

function showTransientFlashMessage(cssClass, message) {
    if (!window.customElements.get('epfb-flash-message')) {
        window.customElements.define('epfb-flash-message', EpfbFlashMessageWebComponent);
    }

    document.body.insertAdjacentHTML(
        'beforeend',
        `<epfb-flash-message class="${cssClass} transient">${message}</epfb-flash-message>`,
    );
}

async function copy(el, content) {
    if (!navigator.clipboard) {
        showTransientFlashMessage(
            'warning',
            `Kein <code>navigator.clipboard</code>-Objekt vorhanden. Dev-Environment?<br>Dieser Inhalt wäre kopiert worden:<br>${content}`,
        );
        return;
    }

    try {
        await navigator.clipboard.writeText(content);

        el
            .querySelector('a svg')
            .querySelectorAll('animate')
            .forEach((animate) => animate.beginElement());

        if (el.getAttribute('data-copied') && 'function' === typeof window[el.getAttribute('data-copied')]) {
            window[el.getAttribute('data-copied')].call(this);
        }
    } catch (err) {
        showTransientFlashMessage('error', `Der Text konnte nicht kopiert werden:<br>${escapeHtml(err.message)}`);
    }
}

export default class extends HTMLElement {
    async connectedCallback() {
        let classAttr = 'btn copy';
        if (this.getAttribute('class')) {
            classAttr += ` ${this.getAttribute('class')}`;
        }
        this.innerHTML = `<a class="${classAttr}">${svg} ${this.innerHTML}</a>`;

        const content = this.getAttribute('data-content');
        if (!content) {
            console.warn(`Text to copy must be specified as value of attribute “data-content” in tag <${this.tagName}>. Copying will not work.`);
            return;
        }

        this.querySelector('a').addEventListener('click', () => {
            copy(this, content);
        });
    }
}
