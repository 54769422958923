function cleanup() {
    const container = document.querySelector('div.flash-message-container');
    const numChildren = container.querySelectorAll('.flash-message').length;
    if (0 === numChildren) {
        container.remove();
    }
}

export default class extends HTMLElement {
    async connectedCallback() {
        let classAttr = 'flash-message';
        const elClass = this.getAttribute('class');
        const elDuration = Number(this.getAttribute('duration'));
        let transient = false;

        if (elClass) {
            const addClasses = elClass.trim().split(/ +/);
            const pos = addClasses.indexOf('transient');
            if (-1 !== pos) {
                transient = true;
                delete addClasses[pos];
            }
            classAttr += ` ${addClasses.join(' ')}`;
        }

        const tag = null === this.innerHTML.match(/<(div|ul|p)[ >]/) ? 'p' : 'div';
        const elHtml = `<${tag} class="${classAttr}">${this.innerHTML}</${tag}>`;

        if (transient) {
            let container = document.querySelector('div.flash-message-container');
            if (!container) {
                document.body.insertAdjacentHTML('afterbegin', '<div class="flash-message-container"></div>');
                container = document.querySelector('div.flash-message-container');
            }
            container.insertAdjacentHTML('beforeend', elHtml);
            const flmsg = container.querySelector('.flash-message:last-child');
            setTimeout(
                () => {
                    flmsg.classList.add('hidden');
                    setTimeout(
                        () => {
                            flmsg.remove();
                            cleanup();
                        },
                        1100,
                    );
                },
                0 < elDuration ? elDuration * 1000 : 4000,
            );
            this.remove();
        } else {
            this.innerHTML = elHtml;
        }
    }
}
