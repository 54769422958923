export default class extends HTMLElement {
    static get observedAttributes() {
        return ['disabled'];
    }

    async connectedCallback() {
        const disabledAttr = this.hasAttribute('disabled') ? ' disabled' : '';
        const classAttr = this.classList.contains('danger') ? ' class="danger"' : '';
        this.innerHTML = `<button type="submit"${disabledAttr}${classAttr}>${this.innerHTML}</button>`;

        const button = this.querySelector('button');
        const form = button.form;

        if (form) {
            form.addEventListener('submit', () => {
                button.setAttribute('disabled', 'disabled');
                document.body.insertAdjacentHTML(
                    'afterbegin',
                    '<div id="loader-backdrop"><div class="loader"></div></div>',
                );
                const backdrop = document.getElementById('loader-backdrop');
                setTimeout(
                    () => backdrop.classList.add('visible'),
                    50,
                );
                setTimeout(
                    () => {
                        // Timeout
                        backdrop.classList.remove('visible');
                        backdrop.parentNode.removeChild(backdrop);
                        button.removeAttribute('disabled');
                    },
                    20000,
                );
            });
        } else {
            console.warn(`${this.tagName} is not enclosed by a <form> element`);
        }
    }

    async attributeChangedCallback() {
        const button = this.querySelector('button');
        if (button) {
            if (this.hasAttribute('disabled')) {
                button.setAttribute('disabled', 'disabled');
            } else {
                button.removeAttribute('disabled');
            }
        }
    }
}
